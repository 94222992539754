import { environment } from "../../../environments/environment";
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IonSearchbar, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/angular';
import { ModalController } from "@ionic/angular";
// import { GuestlogModal } from "@/_modals/guestlog-modal/guestlog-modal";
import { AuthService, StorageService } from '@/_services';
import { CurrentUser, QueueLog, User } from '@/_models';

@Component({
	selector: 'app-select-user-modal',
	templateUrl: './select-user-modal.html',
	styleUrls: ['./select-user-modal.scss'],
	providers : [ TranslateService ]

})
export class SelectUserModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	BASE_URL = environment.base_url;
	currentUser: CurrentUser;

	users: any[] = [];
	filteredUsers: any[] = [];
	searchString: string = '';

	filter: any;

	@HostListener("window:keyboardWillShow", ["$event"])
	keyboardWillShow(event?: Event) {
	  console.log("Keyboard will Show");
	}
	@HostListener("window:keyboardDidShow", ["$event"])
	keyboardDidShow(event?: Event) {
	  console.log("Keyboard did Show");
	}
	
	constructor(
		private auth: AuthService,
		public viewCtrl: ModalController,
		private storage: StorageService,
		private translateService: TranslateService
	) { 
		// this.currentUser = this.auth.currentUser.value;
		// this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}

	ngOnInit() {
		this.filteredUsers = this.users;
	}
	setFilteredUsers() {
		this.filteredUsers = this.filterUsers(this.searchString);
	}
	filterUsers(searchString: any) {
		return this.users.filter(user => {
			return (
				user.fullName.toLowerCase().startsWith(searchString.toLowerCase()) // guest fisrt name
				|| user.fullName.toLowerCase().includes(' ' + searchString.toLowerCase()) // guest other names
				|| user.email.toLowerCase().startsWith(searchString.toLowerCase()) // guest email
				|| user.phone.toLowerCase().includes(searchString.toLowerCase()) // guest phone
			)
		});
	}
	async selectUser(user?: any) {
		this.dismiss(user);
	}
	get listHeaderText(): string {
		return this.filter === 'pending'
			? 'Sign-Out Pending'
			: this.filter;
	}
	get listHeaderClass(): string {
		return this.filter;
	}
	dismiss(user?: any) {
		this.viewCtrl.dismiss(user);
	}
	
}
