import { AuthService } from "./auth.service";
import { AppDataService } from "./app-data.service";
import { StorageService } from "./storage.service";
import { Toast } from '@capacitor/toast';
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { CurrentUser } from "@/_models";
import jwt_decode from "jwt-decode";
import { io } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class SocketService {
	socketConnecting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private BASE_URL = environment.base_url;
	private socketData: any;
	private socket: any;

	constructor(
		public auth: AuthService, 
		public storage: StorageService, 
		public appDataService: AppDataService, 
	) {}

	public async connectSocket(user: CurrentUser) {
		// console.log('=============> connecting socket');
		this.socketConnecting.next(true);
		const token: any = jwt_decode(user.token);
		const clientDB = token.data.clientDB;

		this.socket = io(
			this.BASE_URL + ':4040', 
			{ 
				transports: ["websocket"],
				upgrade: false,
				reconnectionAttempts: 3,
				query: {
					"clientDB": clientDB,
					"user": JSON.stringify(
						{
							"userId": user.userId,
							"fullName": user.fullName,
							"permission": user.permission,
							"selectedLocation": user.selectedLocation
						}
					)
				}
			}
		);
		this.socket.on(clientDB + '_guestlog', (data: any) => {
			// console.log('socketData', data);
			this.setSocketData(data);
			this.syncData();
		});
		this.socket.on(clientDB + '_spacelog', (data: any) => {
			this.setSocketData(data);
			this.syncData();
		});
		this.socket.on(clientDB + '_user', (data: any) => {
			// console.log('user deets updated', data);
			this.setSocketData(data);
			this.syncData();
		});
		this.socket.on(clientDB + '_tag', (data: any) => {
			this.setSocketData(data);
			this.syncData();
		});
		this.socket.on(clientDB + '_location', (data: any) => {
			this.setSocketData(data);
			this.messageReload('Location Policies Updated')
		});



		this.socket.on( 'connect', () => {
			this.socketConnecting.next(false);
			this.syncData();
			// console.log('socket id', this.socket.id);
			// console.log('===>>> socketConnected');
			Toast.show({
				text: 'Connected to Eonii Services',
			});
		});
		this.socket.on( 'disconnect', (reason) => {
			if (reason === "io server disconnect") {
				// the disconnection was initiated by the server, you need to reconnect manually
				// console.log('===>>> socketDisconnected (via server)');
				this.socket.connect();
				Toast.show({
					text: 'Disconnected from Eonii Services',
				});

			} else if (reason === "io client disconnect") {
				// the disconnection was initiated by the client, you need to reconnect manually
				// console.log('===>>> socketDisconnected (via client)');
				// this.socketConnected.next(false);
				// this.socket.connect();
				Toast.show({
					text: 'Disconnected from Eonii Services',
				});

			} else if (reason === "ping timeout") {
				// The server did not send a PING within the pingInterval + pingTimeout range
				// console.log('===>>> socketPingTimeout');
				// this.socketConnected.next(false);
				this.socket.connect();

			} else if (reason === "transport close") {
				// the disconnection was due to a lost connection, or network changed (eg. WiFi to 4G)
				// console.log('===>>> socket ConnectionLost or NetworkChange');

			} else if (reason === "transport error") {
				// The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle)
				// console.log('===>>> transport error');
				this.socket.connect();
			}
			// else the socket will automatically try to reconnect
		});
		this.socket.on("connect_error", (error) => {
			// console.log('===>>> connection error:', error);
		});
		this.socket.on("connect_failed", (error) => {
			// console.log('===>>> connection failed:', error);
		});
		this.socket.on('reconnect', (attempt) => {
			// console.log('===>>> reconnected on attempt ' + attempt);
			this.socketConnecting.next(false);
		});
		this.socket.io.on("reconnect_attempt", (attempt) => {
			// console.log('===>>> reconnection attempt ' + attempt);
			this.socketConnecting.next(true);
		});
		this.socket.io.on("reconnect_error", (error) => {
			// console.log('===>>> reconnect error');
			// ...
		});
		this.socket.io.on("reconnect_failed", () => {
			// console.log('=====>>>>> reconnect failed');
			this.disconnect();
			this.socketConnecting.next(false);
		});
	}

	public disconnect() {
		this.socket?.disconnect();
	}

	public async reconnect() {
		this.socket.disconnect();
		setTimeout(async () => {
			await Toast.show({
				text: 'Reconnecting to Front Office Services',
			});
			this.socket.connect();
		}, 1000);
	}

	public setSocketData(data: any) {
		this.socketData = data;
	}

	public get getSocketData() {
		return this.socketData;
	}
	public get connected(): boolean {
		return this.socket?.connected;
	}

	public async messageReload(msg: any) {
		await Toast.show({
			text: msg,
		});
		setTimeout(() => {
			this.auth.resetCurrentUser()
				.pipe(first())
				.subscribe(
					async (response: any) => {
						await this.storage.setCurrentUser(response.currentUser).then(() => {
							this.auth.currentUser.next(response.currentUser);
							
						})
						// localStorage.setItem(
						// 	'serverTimestamp', 
						// 	response.serverTimestamp
						// );
					},
					(error) => {
						console.log('Reset Error', error);
					}
				);
		}, 2000);
	}

	public getSocketConnecting(): Observable<boolean> {
		return this.socketConnecting.asObservable();
	}

	public syncData() {
		// console.log('sync action');
		this.appDataService.syncData()
	}

}
