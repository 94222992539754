import { environment } from "../../../environments/environment";
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IonSearchbar, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/angular';
import { ModalController } from "@ionic/angular";
// import { GuestlogModal } from "@/_modals/guestlog-modal/guestlog-modal";
import { AuthService, StorageService } from '@/_services';
import { CurrentUser, QueueLog, User } from '@/_models';

@Component({
	selector: 'app-select-guestlog-modal',
	templateUrl: './select-guestlog-modal.html',
	styleUrls: ['./select-guestlog-modal.scss'],
	providers : [ TranslateService ]

})
export class SelectGuestLogModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	BASE_URL = environment.base_url;
	currentUser: CurrentUser;

	users: any[] = [];
	searchString: string = '';

	filter: any;
	guestlogs: any[] = []
	filteredGuestlogs: any[] = [];

	@HostListener("window:keyboardWillShow", ["$event"])
	keyboardWillShow(event?: Event) {
	  console.log("Keyboard will Show");
	}
	@HostListener("window:keyboardDidShow", ["$event"])
	keyboardDidShow(event?: Event) {
	  console.log("Keyboard did Show");
	}
	
	constructor(
		private auth: AuthService,
		public viewCtrl: ModalController,
		private storage: StorageService,
		private translateService: TranslateService
	) { 
		this.currentUser = this.auth.currentUser.value;
		this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}

	ngOnInit() {
		this.filteredGuestlogs = this.guestlogs;
	}
	setFilteredGuestlogs() {
		this.filteredGuestlogs = this.filterGuestlogs(this.searchString);
	}
	filterGuestlogs(searchString: any) {
		return this.guestlogs.filter(guestlog => {
			return (
				guestlog.tagNumber.toLowerCase().includes(searchString.toLowerCase()) // guest queue code
				|| guestlog.guestName.toLowerCase().startsWith(searchString.toLowerCase()) // guest fisrt name
				|| guestlog.guestName.toLowerCase().includes(' ' + searchString.toLowerCase()) // guest other names
				|| guestlog.guestEmail.toLowerCase().startsWith(searchString.toLowerCase()) // guest email
				|| guestlog.guestPhone.toLowerCase().includes(searchString.toLowerCase()) // guest phone
			)
		});
	}
	async selectGuestLog(guestlog?: QueueLog) {
		this.dismiss(guestlog);
	}
	get listHeaderText(): string {
		return this.filter === 'pending'
			? 'Sign-Out Pending'
			: this.filter;
	}
	get listHeaderClass(): string {
		return this.filter;
	}
	dismiss(guestlog?: QueueLog) {
		this.viewCtrl.dismiss(guestlog);
	}
	
}
