import { environment } from "../../../environments/environment";
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IonSearchbar, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/angular';
import { ModalController } from "@ionic/angular";
// import { GuestlogModal } from "@/_modals/guestlog-modal/guestlog-modal";
import { Animations } from "@/_shared/animations";
import { AuthService, StorageService } from '@/_services';
import { CurrentUser, Service } from '@/_models';
import { serviceOptions } from "@/_shared/consts/services";

@Component({
	selector: 'app-select-service-modal',
	templateUrl: './select-service-modal.html',
	styleUrls: ['./select-service-modal.scss'],
	animations: [ Animations ],
	providers : [ TranslateService ]
})
export class SelectServiceModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	BASE_URL = environment.base_url;
	currentUser: CurrentUser;

	filteredServices: Service[] = [];
	searchString: string = '';
	serviceOptions: Service[];

	preServices: Service[];
	selectedServices: Service[] = [];

	@HostListener("window:keyboardWillShow", ["$event"])
	keyboardWillShow(event?: Event) {
		console.log("Keyboard will Show");
	}
	@HostListener("window:keyboardDidShow", ["$event"])
	keyboardDidShow(event?: Event) {
		console.log("Keyboard did Show");
	}
	
	constructor(
		private auth: AuthService,
		public viewCtrl: ModalController,
		private storage: StorageService,
		private translateService: TranslateService
	) { 
		this.currentUser = this.auth.currentUser.value;
		this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}

	ngOnInit() {
		const fs = this.serviceOptions;
		this.filteredServices = fs;
		this.selectedServices = JSON.parse(JSON.stringify(this.preServices));
	}
	setFilteredServices() {
		this.filteredServices = this.filterServices(this.searchString);
		// console.log('searchContainer Search => ', this.searchContainer.nativeElement.offsetHeight);
	}
	filterServices(searchString: any) {
		return this.serviceOptions.filter(service => {
			return (
				service.name.toLowerCase().startsWith(searchString.toLowerCase()) || // fisrt name
				service.name.toLowerCase().includes(' ' + searchString.toLowerCase()) // tenant names
			)
		});
	}
	isSelected(id: any): boolean {
		return this.selectedServices?.find((el: Service) => el.id === id) !== undefined;
	}
	addRemoveService(service: Service) {
		this.selectedServices.find((el: Service) => el.id === service.id) === undefined
			? this.selectedServices.push(service)
			: this.selectedServices.splice(this.selectedServices.findIndex(el => el.id === service.id), 1);
	}
	selectServices() {
		this.dismiss(this.selectedServices.length > 0 
			? this.selectedServices 
			: undefined
		);
	}
	dismiss(services?: any) {
		if (services) {
			this.viewCtrl.dismiss(services);
		} else {
			this.viewCtrl.dismiss();
		}
	}
	
}
