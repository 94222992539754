import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router, Route, UrlSegment } from '@angular/router';
// import { Storage } from '@capacitor/storage';
import { Preferences } from '@capacitor/preferences';
import { AuthService } from "@/_services/auth.service";

export const CURRENT_USER_KEY = 'currentUser';
 
@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {
 
	constructor(private router: Router, private authService: AuthService) { }
 
	// canLoad(route: Route, segments: UrlSegment[]): boolean {
	// 	const currentUser = this.authService.currentUser.value;
	// 	console.log('oloadi', route.path);
	// 	if (!currentUser) {
	// 		this.router.navigate(["/login"]);
	// 		return false;
	// 	}
	// 	return false;
	// }

	async canActivate(): Promise<boolean> {
		const currentUser = await Preferences.get({ key: CURRENT_USER_KEY });  
		// console.log('curr', currentUser);
		// if (!currentUser && (currentUser?.value === null)) {
		if (currentUser.value  === null) {
			// console.log('currIsNull', currentUser);
			return true;
		} else {
			// console.log('currIsNorNull', currentUser);
			this.router.navigateByUrl('/', { replaceUrl: true });
			return false;
		}
	}
}