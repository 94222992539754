import { environment } from "../../environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout, catchError, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Platform } from '@ionic/angular';
import { AppUpdate } from '@capawesome/capacitor-app-update';

import { 
	StorageService, 
	TOKEN, 
	CURRENT_USER, 
	SERVER_TIMESTAMP, 
	LocalDataset
} from "./storage.service";
import { CurrentUser  } from "@/_models";

// import { Plugins } from '@capacitor/core';
// const { Storage } = Plugins;
 
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// Init with null to filter out the first value in a guard!
	isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	canViewSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	locationSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	// Value subjects
	token: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	serverTimestamp: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	currentUser: BehaviorSubject<CurrentUser> = new BehaviorSubject<CurrentUser>(null);

	// accessMode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	// scanTag: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appMode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appEntryModeSettings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appExitModeSettings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appShowListCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appVoicePrompt: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appCurrentVersion: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	appAvailableVersion: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	private payload: any;
	private API_URL = environment.api_url;
	private BASE_URL = environment.base_url;
	private appVersion: any = { currentVersion: 18, availableVersion: 18 };

	constructor(
		private http: HttpClient,
		private platform: Platform,
		private storage: StorageService
	) {
		// console.log('init authService');
		this.loadCurrentUser();
		this.loadServerTimestamp();
		this.loadShowListCount();
		this.loadVoicePrompt();
		this.loadAppMode();
		this.loadAppVersions();
	}

	async loadCurrentUser() {
		// console.log('=================== loading currentUser');
		await this.storage.getCurrentUser().then((currentUser) => {
			if (currentUser) {
				// console.log('=================== loaded currentUser.selectedLocation', JSON.stringify(currentUser.selectedLocation));
				this.currentUser.next(currentUser);
				this.token.next(currentUser.token);
				this.locationSelected.next(currentUser.selectedLocation !== null);
				this.isAuthenticated.next(true);
				// console.log('=================== loaded locationSelected', this.locationSelected.value);
			} else {
				this.token.next(null);
				this.isAuthenticated.next(false);
				// console.log('=================== No currentUser');
			}
		}).catch((err) => {
			// console.log('=================== loading currentUser Error => ', err);
		});
		// Promise.all(syncOp).then(() => {
		// 	console.log('endStorage sync');			
		// });
	}

	async loadServerTimestamp() {
		const serverTimestamp = await this.storage.getServerTimestamp();
		if (serverTimestamp) {
			this.serverTimestamp.next(serverTimestamp);
		}
	}
	getCurrentUser(): Observable<CurrentUser> {
		return this.currentUser.asObservable();
	}
	
	getServerTimestamp(): Observable<any> {
		return this.serverTimestamp.asObservable();
	}
	
	async loadShowListCount() {
		const showList = await this.storage.getString('appShowListCount');
		this.appShowListCount.next(showList && showList.value === 'true');
	}
	async loadVoicePrompt() {
		const voicePrompt = await this.storage.getString('appVoicePrompt');
		this.appVoicePrompt.next(voicePrompt && voicePrompt.value === 'true');
	}
	async loadAppMode() {
		const appMode = await this.storage.getString('appMode');
		this.appMode.next(appMode.value);
		await this.loadAppModeSettings();
	}
	async loadAppModeSettings() {
		await this.storage.getObject('appEntryModeSettings')
			.then((appEntryModeSettings) => { this.appEntryModeSettings.next(appEntryModeSettings); })
			.catch((err) => { console.log('====== appEntryModeSettings SET Error =====> ', err); });

		await this.storage.getObject('appExitModeSettings')
			.then((appExitModeSettings) => { this.appExitModeSettings.next(appExitModeSettings); })
			.catch((err) => { console.log('====== appExitModeSettings SET Error =====> ', err); });
	}

	loadAppVersions = async () => {
		// this.getAppAvailableVersion('eonii-qm');
		// const appVersion = this.platform.is('android')
		// 	? await AppUpdate.getAppUpdateInfo()
		// 	: this.appVersion;
		this.appCurrentVersion.next(this.appVersion.currentVersion);
		this.appAvailableVersion.next(this.appVersion.availableVersion);
	};
	  


	login(credentials: {email: any, password: any}): Observable<any> {
		return this.http.post<any>(`${this.API_URL}/login`, credentials).pipe(
			map((response) => {
				if (response.status === 1) {
					this.currentUser.next(response.currentUser)
				}
				return response;
			})
			// ,
			// catchError((error) => {
			// 	throw error;
			// })
		);
	}

	getAppData(userId: any, locationId: any) {
		this.payload = {
			locationId: locationId,
			userId: userId,
		};
		return this.http.get<any>(`${this.API_URL}/get-app-qdata`, {
			params: this.payload,
		});   
	}

	syncAppData(payload: any) {
		// payload.locationId = this.currentUserValue.selectedLocation.id;
		return this.http
			.get<any>(`${this.API_URL}/sync-app-qdata`, { params: payload })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	getAppAvailableVersion(appName: any) {
		const payload = {
			appName: appName,
		};
		return this.http
			.get<any>(`${this.API_URL}/get-app-version`, { params: payload }) 
			.pipe(
				map((result) => {
					// console.log('appAvailableVersion', result);
					return result;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	confirmUser(payload: any) {
		return this.http
			.post<any>(`${this.API_URL}/confirm-user`, payload)
			.pipe(
				map((response) => {
					return response;
				})
			// ,
			// catchError((error) => {
			// 	throw error;
			// })
		);
	}

	uploadAvatar(imgData: FormData) {
		const endpoint = "upload-avatar";
		return this.http
			.post<any>(`${this.API_URL}/${endpoint}`, imgData, {
				reportProgress: true,
				observe: "events",
			})
			.pipe(
				map((events) => {
					return events;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	discardAvatar() {
		return this.http
			.post<any>(`${this.API_URL}/discard-avatar`, this.payload)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	updatePreferences(payload: any) {
		payload.action = "update-user-preferences";
		return this.http
			.post<any>(`${this.API_URL}/${payload.action}`, payload)
			.pipe(
				map((response) => {
					// if (response.status === 1) {
					// 	this.currentUser.next(response.data);
					// }
					return response;
				}),
				catchError((error) => {
					throw error;
				})
				// catchError(AuthService.handleError)
			);
	}

	public resetCurrentUser() {
		this.payload = {
			action: "reset-current-user",
			selectedLocationId: this.currentUser.value.selectedLocation.id,
		};
		return this.http.get<any>(`${this.API_URL}/reset-current-user`, {
			params: this.payload,
		});
	}

	getQRData(payload: any) {
		return this.http
			.get<any>(`${this.BASE_URL}/models/thirdparty/qrdata.php`, { params: payload })
			.pipe(
				timeout(6000), //TODO: manage UI display
				map((result) => {
					return result;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	getGuestProfile(payload: any) {
		payload.action = "get-guest-profile";
		return this.http
			.get<any>(`${this.API_URL}/get-guest-profile`, { params: payload })
			.pipe(
				// timeout(6000), //TODO: manage UI display
				map((result) => {
					return result;
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	getGuestHistory(guestId: string) {
		this.payload = {
			guestId: guestId,
			action: "get-guest-history",
			// locationId: locationId,
			locationId: this.currentUser.value.selectedLocation.isTenant
				? this.currentUser.value.selectedLocation.facilityId
				: this.currentUser.value.selectedLocation.id,
		};
		return this.http.get<any>(`${this.API_URL}/get-guest-history`, {
			params: this.payload,
		});
	}

	postGuestLog(payload: any) {
		return this.http
			.post<any>(`${this.API_URL}/${payload.action}`, payload)
			.pipe(
				map((result) => {
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}

	postSpaceLog(payload: any) {
		return this.http
			.post<any>(`${this.API_URL}/${payload.action}`, payload)
			.pipe(
				map((result) => {
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}

	public sanitizeReformat() {
		// console.log('gdu-init');
		const action = "sanitize-reformat-host-ids";
		return this.http
			.post<any>(`${this.API_URL}/${action}`, this.payload)
			.pipe(
				map((result) => {
					// console.log('gdu-result: ', result);
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}


	public getSanitized() {
		// console.log('gdu-init');
		const action = "get-sanitized-host-ids";
		return this.http
			.post<any>(`${this.API_URL}/${action}`, this.payload)
			.pipe(
				map((result) => {
					// console.log('gdu-result: ', result);
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}

	async logout(): Promise<void> {
		await this.storage.clear();
		this.isAuthenticated.next(null);
		this.locationSelected.next(false);
		
		this.token.next(null);
		this.appMode.next({ value: 'none' });
		this.currentUser.next(null);
		this.serverTimestamp.next(null);
	}
}