import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		private platform: Platform,
	) {
		this.initializeApp();
		this.platform.backButton.subscribe(() => {
			console.log("BACK");
		});
	}

	initializeApp() {
		this.platform.ready().then(() => {
			const setStatusBarStyleLight = async () => {
				StatusBar.setStyle({ style: Style.Light });
			};
			if (this.platform.is('android')) {
				StatusBar.setStyle({ style: Style.Light });
				StatusBar.setOverlaysWebView({ overlay: false });
				StatusBar.setBackgroundColor({ color: '#ffffff' });
			}
			SplashScreen.hide();
		});
	}

}
