import { Injectable } from '@angular/core';
// import { Plugins } from '@capacitor/core';
// import { Storage } from '@capacitor/storage';
import { Preferences } from '@capacitor/preferences';
import { 
	CurrentUser, 
	// Tag, 
	// User, 
	// Guest, 
	// GuestLog, 
	// SpaceLog, 
	// DeliveryLog, 
	// DeliverySender, 
	TenantLocation
} from '../_models';
// import { transformAppData } from '../_shared/utilities';

// export const { Storage } = Plugins;
export const TOKEN = 'token';
export const APP_MODE = 'appMode';
export const CURRENT_USER = 'currentUser';
export const SERVER_TIMESTAMP = 'serverTimestamp';
export const DEVICE_DEFAULTS = 'deviceDefaults';

export const LocalDataset = {
	TAGS: "tags",
	USERS: "users",
	GUESTS: "guests",
	STATIONS: "stations",
	SERVICES: "services",
	QUEUELOGS: "queueLogs",
};

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { 
		// console.log('init storageService');
	}

    async setDeviceDefaults(deviceDefaults: any) {
        await Preferences.set({ key: DEVICE_DEFAULTS, value: JSON.stringify(deviceDefaults) });
    }
	async getDeviceDefaults(): Promise<any> {
		const ret = await Preferences.get({ key: DEVICE_DEFAULTS });
        return JSON.parse(ret.value);
	}


	async setToken(token: any) {
        await Preferences.set({ key: TOKEN, value: token });
    }
	async getToken(): Promise<{ value: any }> {
        return await Preferences.get({ key: TOKEN });
	}


    async setServerTimestamp(serverTimestamp: any) {
        await Preferences.set({ key: SERVER_TIMESTAMP, value: JSON.stringify(serverTimestamp) });
    }
	async getServerTimestamp(): Promise<any> {
		const ret = await Preferences.get({ key: SERVER_TIMESTAMP });
        return JSON.parse(ret.value);
	}


    async setCurrentUser(currentUser: any) {
        await Preferences.set({ key: CURRENT_USER, value: JSON.stringify(currentUser) });
    }
	async getCurrentUser(): Promise<CurrentUser> {
        const ret = await Preferences.get({ key: CURRENT_USER });
        return JSON.parse(ret.value);
	}


	async setAppMode(appMode: any) {
        await Preferences.set({ key: APP_MODE, value: appMode });
    }
	async getAppMode(): Promise<{ value: any }> {
        return await Preferences.get({ key: APP_MODE });
	}


    async setAppData(user: CurrentUser, dataset: any) {
		const data = {};
		dataset.map((el) => {
			data[el.name] = el.data;
		});
		// console.log('data => ', data);
		if (user.permission.bookGuest 
				|| user.permission.reserveSpaces 
				|| user.permission.approveSpaces) {
			await this.setUsers(data[LocalDataset.USERS]);
		}
		await this.setTags(data[LocalDataset.TAGS]);
		await this.setGuests(data[LocalDataset.GUESTS]);
		await this.setStations(data[LocalDataset.STATIONS]);
		await this.setServices(data[LocalDataset.SERVICES]);

		// if (user.selectedLocation.isFacility) {
		// 	this.setTenants(data[LocalDataset.TENANTS]);
		// }
		// this.setUserLogs(data[LocalDataset.USERLOGS]);
		await this.setQueueLogs(data[LocalDataset.QUEUELOGS]);
		// await this.setSpaceLogs(data[LocalDataset.SPACELOGS]);
		// await this.setDeliveryLogs(data[LocalDataset.DELIVERYLOGS]);
	}

	async syncStoredData(dataset: any, timestamp: any) {
		// console.log('startStorage sync');
		await this.setServerTimestamp(timestamp);
		const syncOp = dataset.map(async (el: any) => {
			if (el.data.length > 0) {
				await this.sync(el.name, el.data);
			}
		});
		await Promise.all(syncOp).then(() => {
			// console.log('endStorage sync');			
		});
	}

	async sync(dataset: any, data: any) {
		// console.log('innerSync', dataset);
		const raw = await Preferences.get({ key: dataset });
		const datalist = JSON.parse(raw.value);
		data.map((el) => {
			const index = datalist.findIndex((b) => b.id === el.id);
			if (index < 0) {
				datalist.unshift(el);
			} else {
				if (dataset === LocalDataset.QUEUELOGS) {
					datalist.splice(index, 1);
					if (el.status !== 'DL') {
						datalist.unshift(el);
					}
				} else {
					datalist[index] = el;
				}
			}
		});
		await Preferences.set({ key: dataset, value: JSON.stringify(datalist) }).then(() => {
			// console.log('innerStore', dataset);
			return true;
		});
	}

	async update(dataset: any, record: any) {
		const raw = await Preferences.get({ key: dataset });
		const datalist = JSON.parse(raw.value);
		const index = datalist.findIndex((r) => r.id === record.id);
		datalist[index] = record;
		await Preferences.set({ key: dataset, value: JSON.stringify(datalist) });
	}

	
    async setQueueLogs(queuelogs: any) {
        await Preferences.set({ key: LocalDataset.QUEUELOGS, value: JSON.stringify(queuelogs) });
    }
	// async getGuestLogs(): Promise<GuestLog[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.GUESTLOGS });
    //     return JSON.parse(ret.value);
	// }


    // async setSpaceLogs(spacelogs: any) {
    //     await Preferences.set({ key: LocalDataset.SPACELOGS, value: JSON.stringify(spacelogs) });
    // }
	// async getSpaceLogs(): Promise<SpaceLog[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.SPACELOGS });
    //     return JSON.parse(ret.value);
	// }


    // async setDeliveryLogs(deliverylogs: any) {
    //     await Preferences.set({ key: LocalDataset.DELIVERYLOGS, value: JSON.stringify(deliverylogs) });
    // }
	// async getDeliveryLogs(): Promise<DeliveryLog[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.DELIVERYLOGS });
    //     return JSON.parse(ret.value);
	// }


    async setGuests(guests: any) {
        await Preferences.set({ key: LocalDataset.GUESTS, value: JSON.stringify(guests) });
    }
	// async getGuests(): Promise<Guest[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.GUESTS });
    //     return JSON.parse(ret.value);
	// }

    async setStations(senders: any) {
        await Preferences.set({ key: LocalDataset.STATIONS, value: JSON.stringify(senders) });
    }
	// async getSenders(): Promise<DeliverySender[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.SENDERS });
    //     return JSON.parse(ret.value);
	// }

    async setServices(services: any) {
        await Preferences.set({ key: LocalDataset.SERVICES, value: JSON.stringify(services) });
    }
	// async getSenders(): Promise<DeliverySender[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.SENDERS });
    //     return JSON.parse(ret.value);
	// }

    async setUsers(users: any) {
        await Preferences.set({ key: LocalDataset.USERS, value: JSON.stringify(users) });
    }
	// async getUsers(): Promise<User[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.USERS });
    //     return JSON.parse(ret.value);
	// }

    async setTags(tags: any) {
        await Preferences.set({ key: LocalDataset.TAGS, value: JSON.stringify(tags) });
    }
	// async getTags(): Promise<Tag[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.TAGS });
    //     return JSON.parse(ret.value);
	// }

    // async setTenants(tenants: any) {
    //     await Preferences.set({ key: LocalDataset.TENANTS, value: JSON.stringify(tenants) });
    // }
	// async getTenants(): Promise<TenantLocation[]> {
    //     const ret = await Preferences.get({ key: LocalDataset.TENANTS });
    //     return JSON.parse(ret.value);
	// }

    async setString(key: string, value: string) {
        await Preferences.set({ key, value });
    }
	async getString(key: string): Promise<{ value: any }> {
        return (await Preferences.get({ key }));
    }

    async setObject(key: string, value: any) {
        await Preferences.set({ key, value: JSON.stringify(value) });
    }
    async getObject(key: string): Promise<{ value: any }> {
		// console.log('key', key);
        const ret = await Preferences.get({ key: key });
        return JSON.parse(ret.value);
    }

    async remove(key: string) {
        await Preferences.remove({ key });
    }

    async clear() {
		await this.remove(TOKEN);
		await this.remove(CURRENT_USER);
		await this.remove(SERVER_TIMESTAMP);
		await this.remove(LocalDataset.TAGS);
		await this.remove(LocalDataset.USERS);
		await this.remove(LocalDataset.GUESTS);
		// await this.remove(LocalDataset.TENANTS);
		await this.remove(LocalDataset.STATIONS);
		await this.remove(LocalDataset.SERVICES);
		await this.remove(LocalDataset.QUEUELOGS);
		// await this.remove(LocalDataset.SPACELOGS);
		// await this.remove(LocalDataset.DELIVERYLOGS);
        // await Storage.clear();
    }
}