import { Country, CurrentUser } from '@/_models';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, IonSearchbar } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Animations } from "@/_shared/animations";
import { AuthService } from "@/_services";
import { countryOptions } from "@/_shared/consts/countries";


@Component({
	selector: 'app-select-country-code-modal',
	templateUrl: './select-country-code-modal.html',
	styleUrls: ['./select-country-code-modal.scss'],
	animations: [ Animations ]
})
export class SelectCountryCodeModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	currentUser: CurrentUser;
	selectedCountryId: any;
	// countryOptions = countryOptions;
	countryOptions = countryOptions.map((el: any) => {
		el.code = el.isoCode2.toLowerCase();
		return el;
	});
	filteredCountries: Country[] = [];
	searchString: string = '';


	constructor(
		public auth: AuthService,
		public viewCtrl: ModalController,
		private translateService: TranslateService
	) {
		this.currentUser = this.auth.currentUser.value;
		this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}
	ngOnInit() {
		this.filteredCountries = this.countryOptions;
	}
	setFilteredCountries() {
		this.filteredCountries = this.filterCountries(this.searchString);
		// console.log('searchContainer Search => ', this.searchContainer.nativeElement.offsetHeight);
	}
	filterCountries(searchString: any) {
		return this.countryOptions.filter(country => {
			return (
				country.phonePrefix.includes(searchString) || // phone prefix
				country.name.toLowerCase().startsWith(searchString.toLowerCase()) || // fisrt name
				country.name.toLowerCase().includes(' ' + searchString.toLowerCase()) // other names
			);
		});
	}
	
	async selectCountry(ev: CustomEvent) {
		if (ev.detail.value !== this.selectedCountryId) {
			const country = this.countryOptions.find((el) => el.id === parseInt(ev.detail.value));
			// console.log('selCountry', country);
			this.dismiss(country);
		}
	}
	dismiss(countryCode?: any) {
		this.viewCtrl.dismiss(countryCode)
	}
}
