import {sequence,trigger,animate,style,group,query,transition,animateChild,state,animation,useAnimation,stagger} from "@angular/animations";

const customAnimation = animation(
	[
		style({
			opacity: "{{opacity}}",
			transform: "scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})",
		}),
		animate(
			"{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)",
			style("*")
		),
	],
	{
		params: {
			duration: "200ms",
			delay: "0ms",
			opacity: "0",
			scale: "1",
			x: "0",
			y: "0",
			z: "0",
		},
	}
);

export const Animations = [
	trigger("animate", [
		transition("void => *", [useAnimation(customAnimation)]),
	]),
	trigger("cardDefault", [transition(":enter", [])]),
	trigger("fadeInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("300ms ease-in", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("300ms ease-in", style({ opacity: 0 })),
		]),
	]),
	trigger("growInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translate(-50%, -50%) scale(0.7, 0.7)" }),
			animate("280ms linear", style({ opacity: 1, transform: "translate(-50%, -50%) scale(1, 1)" })),
		]),
		transition(":leave", [
			animate("280ms linear", style({ opacity: 0, transform: "translate(-50%, -50%) scale(0.7, 0.7)" })),
		]),
	]),
	trigger("peekInOut", [
		transition(":enter", [
			style({ transform: "translateY(100%)" }),
			animate("280ms linear", style({ transform: "translateY(0%)" })),
		]),
		transition(":leave", [
			animate("280ms linear", style({ transform: "translateY(100%)" })),
		]),
	]),
	trigger("ScanBoxInOut", [
		transition(":enter", [
			style({ opacity: 0, height: "120px", width: "120px" }),
			animate("300ms ease-in-out", style({ opacity: 1, height: "440px", width: "48%" })),
		]),
		transition(":leave", [
			animate("300ms ease-in-out", style({ opacity: 0, height: "120px", width: "120px" })),
		]),
	]),
	trigger("fadeSlideInOutLeft", [
		transition(":enter", [
			style({ opacity: 0, marginLeft: "-80px" }),
			animate("300ms linear", style({ opacity: 1, marginLeft: "0px" })),
		]),
		transition(":leave", [
			animate("300ms linear", style({ opacity: 0, marginLeft: "-80px" })),
		]),
	]),

	trigger("slideDownInOut", [
		transition(":enter", [
			style({ transform: "translateY(-100%)" }),
			animate("300ms ease-in", style({ transform: "translateY(0%)" })),
		]),
		transition(":leave", [
			animate("0ms ease-in", style({ transform: "translateY(100%)" })),
		]),
	]),
	trigger("slideUpInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateY(50%)" }),
			animate("300ms ease-in", style({ opacity: 1, transform: "translateY(0%)" })),
		]),
		transition(":leave", [
			animate("0ms ease-in", style({ opacity: 0, transform: "translateY(50%)" })),
		]),
	]),
	// trigger("slideInOut", [
	// 	state(
	// 		"0",
	// 		style({
	// 			height: "0px",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"1",
	// 		style({
	// 			height: "*",
	// 			display: "block",
	// 		})
	// 	),
	// 	transition("1 => 0", animate("300ms ease-out")),
	// 	transition("0 => 1", animate("300ms ease-in")),
	// ]),

	trigger("pageShowSlow", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("1000ms ease-in", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("500ms ease-in", style({ opacity: 0 })),
		]),
	]),
	trigger("cardPrompt", [
		transition(":enter", [
			style({ opacity: 0, marginTop: "-42px" }),
			animate(
				"300ms ease-out", 
				style({ opacity: 1, marginTop: "0px" })),
		]),
		transition(":leave", [
			animate(
				"300ms ease-in", 
				style({ opacity: 0, marginTop: "-42px" })),
		]),
	]),
	trigger("inputPrompt", [
		transition(":enter", [
			style({ opacity: 0, marginTop: "-36px" }),
			animate(
				"300ms ease-out", 
				style({ opacity: 1, marginTop: "-15px" })),
		]),
		transition(":leave", [
			animate(
				"300ms ease-in", 
				style({ opacity: 0, marginTop: "-36px" })),
		]),
	]),
	trigger("formInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(-50%)" }),
			animate(
				"360ms ease-in",
				style({ opacity: 1, transform: "translateX(0%)" })
			),
		]),
		transition(":leave", [
			// style({opacity: 1, transform: 'translateY(0%)'}),
			animate(
				"360ms ease-in",
				style({ opacity: 0, transform: "translateX(-50%)" })
			),
		]),
	]),
	trigger("locationsInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(50%)" }),
			animate(
				"360ms ease-in",
				style({ opacity: 1, transform: "translateX(0%)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in",
				style({ opacity: 0, transform: "translateX(50%)" })
			),
		]),
	]),
	trigger("isProcessing", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("360ms ease-in", style({ opacity: 1 })),
		]),
		transition(":leave", [animate("360ms ease-in", style({ opacity: 0 }))]),
	]),
	trigger("hasNotification", [
		transition(":enter", [
			style({ opacity: 0, marginTop: "-25px", marginBottom: "-7px" }),
			animate(
				"360ms ease",
				style({
					opacity: 1,
					marginTop: "0px",
					marginBottom: "15px",
				})
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease",
				style({
					opacity: 0,
					marginTop: "-25px",
					marginBottom: "-7px",
				})
			),
		]),
	]),
	trigger("hasLoginNotification", [
		transition(":enter", [
			style({ 
				opacity: 0, 
				marginBottom: "-6px" 
			}),
			animate(
				"300ms ease",
				style({
					opacity: 1,
					marginBottom: "12px",
				})
			),
		]),
		transition(":leave", [
			animate(
				"300ms ease",
				style({
					opacity: 0,
					marginBottom: "-6px",
				})
			),
		]),
	]),
	trigger("animateStagger", [
		state("50", style("*")),
		state("100", style("*")),
		state("200", style("*")),

		transition(
			"void => 50",
			query("@*", [stagger("50ms", [animateChild()])], { optional: true })
		),
		transition(
			"void => 100",
			query("@*", [stagger("100ms", [animateChild()])], {
				optional: true,
			})
		),
		transition(
			"void => 200",
			query("@*", [stagger("200ms", [animateChild()])], {
				optional: true,
			})
		),
	]),

	// trigger("fadeInOut", [
	// 	state(
	// 		"0",
	// 		style({
	// 			display: "none",
	// 			opacity: 0,
	// 		})
	// 	),
	// 	state(
	// 		"1",
	// 		style({
	// 			display: "block",
	// 			opacity: 1,
	// 		})
	// 	),
	// 	transition("1 => 0", animate("300ms ease-out")),
	// 	transition("0 => 1", animate("300ms ease-in")),
	// ]),

	// trigger("slideInOut", [
	// 	state(
	// 		"0",
	// 		style({
	// 			height: "0px",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"1",
	// 		style({
	// 			height: "*",
	// 			display: "block",
	// 		})
	// 	),
	// 	transition("1 => 0", animate("300ms ease-out")),
	// 	transition("0 => 1", animate("300ms ease-in")),
	// ]),

	trigger("slideIn", [
		transition("void => left", [
			style({
				transform: "translateX(100%)",
			}),
			animate(
				"300ms ease-in",
				style({
					transform: "translateX(0)",
				})
			),
		]),
		transition("left => void", [
			style({
				transform: "translateX(0)",
			}),
			animate(
				"300ms ease-in",
				style({
					transform: "translateX(-100%)",
				})
			),
		]),
		transition("void => right", [
			style({
				transform: "translateX(-100%)",
			}),
			animate(
				"300ms ease-in",
				style({
					transform: "translateX(0)",
				})
			),
		]),
		transition("right => void", [
			style({
				transform: "translateX(0)",
			}),
			animate(
				"300ms ease-in",
				style({
					transform: "translateX(100%)",
				})
			),
		]),
	]),

	// trigger("slideInLeft", [
	// 	state(
	// 		"void",
	// 		style({
	// 			transform: "translateX(-100%)",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"*",
	// 		style({
	// 			transform: "translateX(0)",
	// 			display: "flex",
	// 		})
	// 	),
	// 	transition("void => *", animate("300ms")),
	// 	transition("* => void", animate("300ms")),
	// ]),

	// trigger("slideInRight", [
	// 	state(
	// 		"void",
	// 		style({
	// 			transform: "translateX(100%)",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"*",
	// 		style({
	// 			transform: "translateX(0)",
	// 			display: "flex",
	// 		})
	// 	),
	// 	transition("void => *", animate("300ms")),
	// 	transition("* => void", animate("300ms")),
	// ]),

	// trigger("slideInTop", [
	// 	state(
	// 		"void",
	// 		style({
	// 			transform: "translateY(-100%)",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"*",
	// 		style({
	// 			transform: "translateY(0)",
	// 			display: "flex",
	// 		})
	// 	),
	// 	transition("void => *", animate("300ms")),
	// 	transition("* => void", animate("300ms")),
	// ]),

	// trigger("slideInBottom", [
	// 	state(
	// 		"void",
	// 		style({
	// 			transform: "translateY(100%)",
	// 			display: "none",
	// 		})
	// 	),
	// 	state(
	// 		"*",
	// 		style({
	// 			transform: "translateY(0)",
	// 			display: "flex",
	// 		})
	// 	),
	// 	transition("void => *", animate("300ms")),
	// 	transition("* => void", animate("300ms")),
	// ]),

	trigger("expandCollapse", [
		state(
			"void",
			style({
				height: "0px",
			})
		),
		state(
			"*",
			style({
				height: "*",
			})
		),
		transition("void => *", animate("300ms ease-out")),
		transition("* => void", animate("300ms ease-in")),
	]),

	// -----------------------------------------------------------------------------------------------------
	// @ Router animations
	// -----------------------------------------------------------------------------------------------------

	// trigger("routerTransitionLeft", [
	// 	transition("* => *", [
	// 		query(
	// 			"content > :enter, content > :leave",
	// 			[
	// 				style({
	// 					position: "absolute",
	// 					top: 0,
	// 					bottom: 0,
	// 					left: 0,
	// 					right: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		query(
	// 			"content > :enter",
	// 			[
	// 				style({
	// 					transform: "translateX(100%)",
	// 					opacity: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		sequence([
	// 			group([
	// 				query(
	// 					"content > :leave",
	// 					[
	// 						style({
	// 							transform: "translateX(0)",
	// 							opacity: 1,
	// 						}),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateX(-100%)",
	// 								opacity: 0,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 				query(
	// 					"content > :enter",
	// 					[
	// 						style({ transform: "translateX(100%)" }),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateX(0%)",
	// 								opacity: 1,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 			]),
	// 			query("content > :leave", animateChild(), { optional: true }),
	// 			query("content > :enter", animateChild(), { optional: true }),
	// 		]),
	// 	]),
	// ]),

	// trigger("routerTransitionRight", [
	// 	transition("* => *", [
	// 		query(
	// 			"content > :enter, content > :leave",
	// 			[
	// 				style({
	// 					position: "absolute",
	// 					top: 0,
	// 					bottom: 0,
	// 					left: 0,
	// 					right: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		query(
	// 			"content > :enter",
	// 			[
	// 				style({
	// 					transform: "translateX(-100%)",
	// 					opacity: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		sequence([
	// 			group([
	// 				query(
	// 					"content > :leave",
	// 					[
	// 						style({
	// 							transform: "translateX(0)",
	// 							opacity: 1,
	// 						}),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateX(100%)",
	// 								opacity: 0,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 				query(
	// 					"content > :enter",
	// 					[
	// 						style({ transform: "translateX(-100%)" }),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateX(0%)",
	// 								opacity: 1,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 			]),
	// 			query("content > :leave", animateChild(), { optional: true }),
	// 			query("content > :enter", animateChild(), { optional: true }),
	// 		]),
	// 	]),
	// ]),

	// trigger("routerTransitionUp", [
	// 	transition("* => *", [
	// 		query(
	// 			"content > :enter, content > :leave",
	// 			[
	// 				style({
	// 					position: "absolute",
	// 					top: 0,
	// 					bottom: 0,
	// 					left: 0,
	// 					right: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		query(
	// 			"content > :enter",
	// 			[
	// 				style({
	// 					transform: "translateY(100%)",
	// 					opacity: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		group([
	// 			query(
	// 				"content > :leave",
	// 				[
	// 					style({
	// 						transform: "translateY(0)",
	// 						opacity: 1,
	// 					}),
	// 					animate(
	// 						"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 						style({
	// 							transform: "translateY(-100%)",
	// 							opacity: 0,
	// 						})
	// 					),
	// 				],
	// 				{ optional: true }
	// 			),
	// 			query(
	// 				"content > :enter",
	// 				[
	// 					style({ transform: "translateY(100%)" }),
	// 					animate(
	// 						"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 						style({
	// 							transform: "translateY(0%)",
	// 							opacity: 1,
	// 						})
	// 					),
	// 				],
	// 				{ optional: true }
	// 			),
	// 		]),
	// 		query("content > :leave", animateChild(), { optional: true }),
	// 		query("content > :enter", animateChild(), { optional: true }),
	// 	]),
	// ]),

	// trigger("routerTransitionDown", [
	// 	transition("* => *", [
	// 		query(
	// 			"content > :enter, content > :leave",
	// 			[
	// 				style({
	// 					position: "absolute",
	// 					top: 0,
	// 					bottom: 0,
	// 					left: 0,
	// 					right: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		query(
	// 			"content > :enter",
	// 			[
	// 				style({
	// 					transform: "translateY(-100%)",
	// 					opacity: 0,
	// 				}),
	// 			],
	// 			{ optional: true }
	// 		),
	// 		sequence([
	// 			group([
	// 				query(
	// 					"content > :leave",
	// 					[
	// 						style({
	// 							transform: "translateY(0)",
	// 							opacity: 1,
	// 						}),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateY(100%)",
	// 								opacity: 0,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 				query(
	// 					"content > :enter",
	// 					[
	// 						style({ transform: "translateY(-100%)" }),
	// 						animate(
	// 							"600ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 							style({
	// 								transform: "translateY(0%)",
	// 								opacity: 1,
	// 							})
	// 						),
	// 					],
	// 					{ optional: true }
	// 				),
	// 			]),
	// 			query("content > :leave", animateChild(), { optional: true }),
	// 			query("content > :enter", animateChild(), { optional: true }),
	// 		]),
	// 	]),
	// ]),

	// trigger("routerTransitionFade", [
	// 	transition(
	// 		"* => *",
	// 		group([
	// 			query(
	// 				"content > :enter, content > :leave ",
	// 				[
	// 					style({
	// 						position: "absolute",
	// 						top: 0,
	// 						bottom: 0,
	// 						left: 0,
	// 						right: 0,
	// 					}),
	// 				],
	// 				{ optional: true }
	// 			),

	// 			query(
	// 				"content > :enter",
	// 				[
	// 					style({
	// 						opacity: 0,
	// 					}),
	// 				],
	// 				{ optional: true }
	// 			),
	// 			query(
	// 				"content > :leave",
	// 				[
	// 					style({
	// 						opacity: 1,
	// 					}),
	// 					animate(
	// 						"300ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 						style({
	// 							opacity: 0,
	// 						})
	// 					),
	// 				],
	// 				{ optional: true }
	// 			),
	// 			query(
	// 				"content > :enter",
	// 				[
	// 					style({
	// 						opacity: 0,
	// 					}),
	// 					animate(
	// 						"300ms cubic-bezier(0.0, 0.0, 0.2, 1)",
	// 						style({
	// 							opacity: 1,
	// 						})
	// 					),
	// 				],
	// 				{ optional: true }
	// 			),
	// 			query("content > :enter", animateChild(), { optional: true }),
	// 			query("content > :leave", animateChild(), { optional: true }),
	// 		])
	// 	),
	// ]),

	trigger("topBtnInOut", [
		transition(":enter", [
			style({
				opacity: 0.2,
				transform: "translate(-50%, 0%) scale(0.5, 0.5)",
			}),
			animate(
				"300ms ease-in",
				style({
					opacity: 1,
					transform: "translate(-50%, 0%) scale(1, 1)",
				})
			),
		]),
		transition(":leave", [
			animate(
				"300ms ease-in",
				style({
					opacity: 0,
					transform: "translate(-50%, 0%) scale(0.5, 0.5)",
				})
			),
		]),
	]),
	trigger("ListDefault", [transition(":enter", [])]),
	trigger("ListInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(-50%)" }),
			animate(
				"400ms ease-in-out",
				style({ opacity: 1, transform: "translateX(0%)" })
			),
		]),
		transition(":leave", [
			animate(
				"400ms ease-in-out",
				style({ opacity: 0, transform: "translateX(-50%)" })
			),
		]),
	]),
	trigger("DetailInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(50%)" }),
			animate(
				"400ms ease-in-out",
				style({ opacity: 1, transform: "translateX(0%)" })
			),
		]),
		transition(":leave", [
			animate(
				"400ms ease-in-out",
				style({ opacity: 0, transform: "translateX(50%)" })
			),
		]),
	]),
	trigger("RowDetailShowHide", [
		state("collapsed", style({ height: "0px", minHeight: "0" })),
		state("expanded", style({ height: "*" })),
		transition(
			"expanded <=> collapsed",
			animate("360ms cubic-bezier(0.4, 0.0, 0.2, 1)")
		),
	]),
	// trigger("detailExpand", [
	// 	transition(":enter", [
	// 		style({ height: "0px", minHeight: "0", display: "none" }),
	// 		animate(
	// 			"225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
	// 			style({ height: "*" })
	// 		),
	// 	]),
	// 	transition(":leave", [
	// 		style({ height: "*" }),
	// 		animate(
	// 			"225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
	// 			style({ height: "0px", minHeight: "0", display: "none" })
	// 		),
	// 	]),
	// ]),
	trigger("CardButtonShowHideo", [
		transition(":enter", [
			style({ opacity: 0, transform: "scale(0.5, 0.5)" }),
			animate(
				"360ms ease-in",
				style({ opacity: 1, transform: "scale(1, 1)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in",
				style({ opacity: 0, transform: "scale(0.5, 0.5)" })
			),
		]),
	]),
	trigger("CardButtonShowHide", [
		transition(":enter", [
			style({ opacity: 0, transform: "scale(0.5, 0.5)" }),
			animate(
				"360ms ease-in",
				style({ opacity: 1, transform: "scale(1, 1)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in",
				style({ opacity: 0, transform: "scale(0.5, 0.5)" })
			),
		]),
	]),
	trigger("CardSpinnerShowHide", [
		transition(":enter", [
			style({ opacity: 0, transform: "scale(0.5, 0.5)" }),
			animate(
				"360ms ease-in-out",
				style({ opacity: 1, transform: "scale(1, 1)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in-out",
				style({ opacity: 0, transform: "scale(0.5, 0.5)" })
			),
		]),
	]),
	trigger("modalSpinnerShowHide", [
		transition(":enter", [
			style({ opacity: 0, transform: "scale(0.5, 0.5)" }),
			animate(
				"360ms ease-in-out",
				style({ opacity: 1, transform: "scale(1, 1)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in-out",
				style({ opacity: 0, transform: "scale(0.5, 0.5)" })
			),
		]),
	]),
	trigger("modalDetailDefault", [transition(":enter", [])]),
	trigger("modalDetailShowHide", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("200ms ease-in", style({ opacity: 1 })),
		]),
		transition(":leave", [animate("200ms ease-in", style({ opacity: 0 }))]),
	]),
	trigger("AvatarEdit", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("200ms ease-in", style({ opacity: 1 })),
		]),
		transition(":leave", [animate("200ms ease-in", style({ opacity: 0 }))]),
	]),
	trigger("modalPromptShowHide", [
		transition(":enter", [
			style({ opacity: 0, marginTop: "-20px", marginBottom: "-12px" }),
			animate(
				"300ms ease-out",
				style({ opacity: 1, marginTop: "-5px", marginBottom: "10px" })
			),
		]),
		transition(":leave", [
			animate(
				"300ms ease-out",
				style({ opacity: 0, marginTop: "-20px", marginBottom: "-12px" })
			),
		]),
	]),
	trigger("modalPromptInOut", [
		transition(":enter", [
			style({ opacity: 0, marginTop: "-33px" }),
			animate("300ms ease-out", style({ opacity: 1, marginTop: "12px" })),
		]),
		transition(":leave", [
			animate(
				"300ms ease-out",
				style({ opacity: 0, marginTop: "-33px" })
			),
		]),
	]),
	trigger("modalFileListInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("300ms ease-out", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("300ms ease-out", style({ opacity: 0 })),
		]),
	]),
	trigger("modalLinkFadeInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("300ms ease-out", style({ opacity: 0.7 })),
		]),
		transition(":leave", [
			animate("300ms ease-out", style({ opacity: 0 })),
		]),
	]),
	trigger("SettingsInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("400ms ease-out", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("400ms ease-out", style({ opacity: 0 })),
		]),
	]),
	trigger("SearchBtnInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("400ms ease-out", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("400ms ease-out", style({ opacity: 0 })),
		]),
	]),
	trigger("CardBackBtnInOut", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate("400ms ease-out", style({ opacity: 1 })),
		]),
		transition(":leave", [
			animate("400ms ease-out", style({ opacity: 0 })),
		]),
	]),
	trigger("CardDefaultInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(-50%)" }),
			animate(
				"360ms ease-in-out",
				style({ opacity: 1, transform: "translateX(0%)" })
			),
		]),
		transition(":leave", [
			animate(
				"360ms ease-in-out",
				style({ opacity: 0, transform: "translateX(-50%)" })
			),
		]),
	]),
	trigger("CardDetailInOut", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateX(-50%)" }),
			animate(
				"360ms ease-in-out",
				style({ opacity: 1, transform: "translateX(-100%)" })
			),
		]),
		transition(":leave", [
			style({ opacity: 1, transform: "translateX(-100%)" }),
			animate(
				"360ms ease-in-out",
				style({ opacity: 0, transform: "translateX(-50%)" })
			),
		]),
	]),
	trigger("AppPrompt", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateY(-40px)" }),
			animate(
				"360ms linear",
				style({ opacity: 1, transform: "translateY(0px)" })
			),
		]),
		transition(":leave", [
			style({ opacity: 1, transform: "translateY(0px)" }),
			animate(
				"360ms linear",
				style({ opacity: 0, transform: "translateY(-40px)" })
			),
		]),
	]),
];
